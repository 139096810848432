@use './global.scss';

body {
    background-color: global.$body-background;
}

.wrapper {
    display: grid;
    max-width: 1920px;
    margin: auto;
    grid-template-columns: 275px auto;
}

.container {
    max-width: global.$container-size;
    margin: auto;
}

input:focus-visible {
    outline-color: #3D9FE0;
}

p.errorP {
    text-align: center;
    color: red;
    font-size: 16px;
    font-weight: 600;
    padding-top: 10px;
}

p.succsesP {
    text-align: center;
    color: green;
    font-size: 16px;
    font-weight: 600;
    padding-top: 10px;
}

.content {
    max-width: 1480px;
    margin: 0 auto;
    overflow: auto;
    width: 100%;
    padding: 0 25px;

    h1 {
        color: global.$h1-titles;
        font-weight: 900;
        font-size: 35px;       
    }

    .wrapperTable {
        margin: 40px 15px;
        overflow: auto;
    }

    table {
        width: 100%;

        tr {
            height: 40px;

            td {
                padding-top: 9px;
                padding-left: 15px;
            }
        }

        tr {
            background: #fff;
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
            margin-bottom: 10px;
        }

        tr:first-child {
            font-size: 17px;
            font-weight: 600;
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
        }



    }


}


.form {

    input,
    select {
        padding: 0 20px 0 15px;
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
        font-size: 16px;
        min-height: 45px;
        width: 100%;
        border: 1px solid #e0e7eb;
    }

}



button.backBtn {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    margin-left: 10px;
    padding-top: 45px;

    svg {
        width: 35px;
        height: 35px;

        path {
            fill: global.$primary-light;
        }
    }
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

label.switch {
    margin-bottom: -8px !important;
    margin-left: 16px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #97a3af;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 5px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #3D9FE0;
}

input:focus+.slider {
    box-shadow: 0 0 1px #3D9FE0;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}



.pagination {
    gap: 10px;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    padding-top: 30px;

    button.active {
        font-weight: 600;
    }

    svg {
        max-width: 25px;
        cursor: pointer;

        path {
            fill: global.$primary-light;
        }
    }

    button.btnNext svg {
        transform: rotate(180deg);
    }
}



/* Checks */
.checks {

    &__wrapper {
        background-color: #fff;
        box-shadow: -1px 3px 2px 0px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        display: flex;
        gap: 45px;
        margin-bottom: 10px;
        align-items: flex-start;
        flex-direction: column;
        padding: 55px 50px 45px 50px;
        max-width: 600px;
    }

    label {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 10px;
        color: #000;
    }

    input[type="text"] {
        padding: 0 20px 0 15px;
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
        font-size: 16px;
        min-height: 45px;
        width: 100%;
        border: 1px solid #e0e7eb;
    }

    input[type="file"] {
        color: #3d4953;
        font-size: 14px;
        font-weight: 500;
        width: 605px;
        margin-top: 15px;
    }

    button.actionButton {
        float: right;
        padding: 15px 45px;
        margin-top: 10px;
    }

    .tel-wrapper,
    .btn-wrapper {
        width: 100%;
    }

    .btn-wrapper {
        margin-top: -20px;
    }

    p.errorP {
        margin: auto;
        padding-top: 0px;
        margin-top: -12px;
    }


    input[type="file"]::file-selector-button {
        border-radius: 4px;
        padding: 0 16px;
        height: 40px;
        cursor: pointer;
        background-color: #3D9FE0;
        border: 1px solid #3D9FE0;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        margin-right: 16px;
        transition: background-color 200ms;
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        transition: all 0.3s;
        letter-spacing: .2px;
        cursor: pointer;
    }


    input[type="file"]::file-selector-button:hover {
        background-color: #378dc8;
    }


    input[type="file"]::file-selector-button:active {
        background-color: #378dc8;
    }

    hr {
        width: 100%;
        border-bottom: 1px solid #b3b3b3;
    }

}


.checksImage {

    &__wrapper {
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        gap: 45px;
        margin-bottom: 10px;
        align-items: flex-start;
        flex-direction: column;
        padding: 30px 0px 0px 0px;
        max-width: 600px;
    }

    label {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 10px;
        color: #000;
    }

    input[type="file"] {
        color: #3d4953;
        font-size: 14px;
        font-weight: 500;
        width: 350px;
        padding: 5px;
    }


    button.actionButton {
        float: right;
        padding: 15px 45px;
        margin-top: 10px;
    }

    .tel-wrapper,
    .btn-wrapper {
        width: 100%;
    }

    .btn-wrapper {
        margin-top: -20px;
    }

    p.errorP {
        margin: auto;
        padding-top: 0px;
        margin-top: -12px;
    }


    input[type="file"]::file-selector-button {
        border-radius: 4px;
        padding: 0 16px;
        height: 40px;
        cursor: pointer;
        background-color: #3D9FE0;
        border: 1px solid #3D9FE0;
        box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
        margin-right: 16px;
        transition: background-color 200ms;
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        transition: all 0.3s;
        letter-spacing: .2px;
        cursor: pointer;
    }


    input[type="file"]::file-selector-button:hover {
        background-color: #378dc8;
    }


    input[type="file"]::file-selector-button:active {
        background-color: #378dc8;
    }

    hr {
        width: 100%;
        border-bottom: 1px solid #b3b3b3;
    }

}


/* Previous Versions */

.previous-versions {
    padding: 45px 0;
    
    h1 {
        margin-bottom: 25px;
        font-weight: 600;
        color: #3D9FE0;
        border-bottom: 1px solid #b3b3b3;
        padding-bottom: 11px;
        font-size: 30px;
    }
    h2 {
        margin-bottom: 28px;
        font-weight: 600;
        color: #000000;        
        padding-bottom: 11px;
        font-size: 25px;
    }
    h3 {
        margin-bottom: 5px;
        margin-top: 20px;;
        font-weight: 600;
        color: #3D9FE0;
        font-size: 20px;
    }

    li {
        line-height: 25px;
    }
}
