@use '../../../styles/global.scss';

.actionButton {
    background-color: global.$primary-light;
        color: #fff;
        font-size: 14px;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: 600;
        cursor: pointer;
        transition: all .3s;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);

        &:hover {
            background-color: #378dc8;
        }

}

.actionButton:disabled,
.actionButton[disabled]{
  background-color: lightgray;
  cursor: not-allowed;
}



.btnLiberador {
  display: inline-block;

  button.actionButton.nuevoLibrador {
    margin: 10px 0 50px 0;
  }

}


.verCliente {
  background-color: #3D9FE0;
  color: #fff;
  padding: 13px 18px;
  min-height: 40px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 14px;
  max-width: 127px;
  max-height: 45px;
  margin-top: 29px;
  transition: all .3s;

  &:hover {
    background-color: #378dc8;
}

}

.clientManagement.check .verCliente, .clientManagement.cession .verCliente {  
  margin-top: 18px;
  margin-bottom: 22px;
}

button.btnDark.verCliente.liberador {
  margin-bottom: 3px;
}