@use '../../../styles/global.scss';

body:has(.login) {
    background-color: #9eadbd;
}

.login {

    input {
        padding: 0 20px 0 15px;
        border-radius: 5px;
        background: #FFF;
        font-size: 14px; 
        min-height: 50px;       

        &:focus-visible {
            outline-color: #3D9FE0;
        }
    }

    .loginArea {
        background-color: #3d4953!important;
        border-radius: 6px;        
        box-shadow: 0px 4px 30px 0px rgb(0 0 0 / 18%);
        margin: 100px auto;
        max-width: 400px;    
        padding: 23px 20px;
        text-align: center;
        
        img {
            max-width: 250px;
            margin: 10px auto 25px auto;
        }
        label {
            text-align: left;
            margin-bottom: 8px;
            font-weight: 600;
            color: #fff;
        }        
        input {
            width: 100%;
            margin-bottom: 12px;
            background: #ffffff;
            border: 1px solid #ededed;
        }
        input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px white inset;
        }
        button.actionButton {
            width: 100%;
            margin-top: 20px;
        }
        h1 {
            text-align: center;
            font-size: 25px;
            font-weight: 900;
            margin-bottom: 15px;
            color: #fff;
            margin-top: -10px;
        }
        
    }

    .actionButton{
        min-height: 50px;
    } 

}