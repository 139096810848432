@use '../../../styles/global.scss';

.errorinput{
    border: 3px solid #9E4772 !important;


    &:focus-visible {
        outline-color: #9E4772 !important;
      }
}

.errorlabel{
    color:  #9E4772 !important;
}