@use '../../../styles/global.scss';

.clientManagement,
.drawerManagement {
    padding-bottom: 150px;

    .actionButton {
        float     : right;
        padding   : 15px 45px;
        margin-top: 10px;
    }

    label {
        font-size    : 17px;
        font-weight  : 600;
        margin-bottom: 10px;
        color        : #000;
    }

    .formRegister {

        &__wrapper {
            border-radius: 10px;
            background   : #FFF;
            box-shadow   : 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
            margin       : 7px 15px 50px 15px;
            padding      : 55px 50px 70px 50px;

            h1 {
                margin-bottom : 30px;
                font-weight   : 600;
                color         : global.$primary-light;
                border-bottom : 1px solid #b3b3b3;
                padding-bottom: 11px;
                font-size     : 30px;
            }
        }

        &__inputArea {
            display              : grid;
            grid-template-columns: 1fr 1fr;
            gap                  : 10px 50px;

            span.dropDownInternal {
                font-size    : 17px;
                font-weight  : 600;
                margin-bottom: 10px;
                color        : #1d1c5c;

                select {
                    margin-top: 10px;
                }
            }
        }

        input,
        select {
            margin-bottom: 20px;
        }

        p {
            margin-top: 10px;
        }

        .clientOperations {
            h3 {
                margin-top: 30px;
            }
        }

        .btnWpWrapper {
            display              : grid;
            grid-template-columns: 90% 10%;
            align-items          : center;
        }

        .btnWp {
            padding-top : 10px;
            padding-left: 17px;


            button {
                transition: all .3s;
            }

            button:hover {
                background-color: #378dc8 !important;
            }
        }

        input:focus-visible {
            outline-color: #3D9FE0;
        }

        .dropdown-container {
            position: relative;

            .input-container {
                color  : #77889f !important;
                padding: 0 !important;
                display: flex;


                button {
                    margin-left: -14px;
                    padding    : 0;
                    margin-top : 0;
                    height     : 42px;
                    cursor     : pointer;
                }
            }

            .dropdown.visible {
                background-color: #fff;
                box-shadow      : 0 2px 4px rgba(0, 0, 0, .1);
                display         : block;
                margin-top      : -18px;
                border-radius   : 4px;
                max-height      : 450px;
                overflow        : auto;
                margin-bottom   : 20px;
                margin-bottom   : 20px;
                z-index         : 1;
                position        : absolute;
                width           : 100% !important;
            }



            .visible {
                visibility: visible !important;
            }

            .item-dropdown {
                border-bottom : 1px solid #f6f6f6;
                margin-right  : 0;
                padding       : 7px 11px;
                color         : #3d4953 !important;
                font-size     : 15px;
                cursor        : pointer;
                letter-spacing: 0.00938em;
                margin-left   : 5px;
                transition    : background-color 0.3s ease;

                &:hover {
                    background-color: #f5f7f8;
                }

                &:first-child {
                    padding-top: 10px;
                }
            }

            svg path {
                color: #fff;
            }

            button.dlt svg {
                width      : 28px;
                height     : 28px;
                cursor     : pointer;
                margin-left: -59px;

                path {
                    fill: rgb(158 71 114);
                }
            }

            .arrow-trash {
                display     : flex;
                margin-left : -16px;
                margin-right: -40px;
                width       : 40px;
            }

            button.dlt {
                margin-left: 0px !important;
            }

        }

    }

    p.errorP {
        text-align : center;
        color      : red;
        font-size  : 16px;
        font-weight: 600;
        padding-top: 10px;
    }

    p.succsesP {
        text-align : center;
        color      : green;
        font-size  : 16px;
        font-weight: 600;
        padding-top: 10px;
    }

    .form.clientForm {
        margin: 40px 0px;
    }

    .actBtn {
        height    : 35px;
        margin-top: 30px;
        text-align: right;
    }

    select {
        appearance         : none;
        background-image   : url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/Vector.svg?v=1710438503);
        background-position: right 15px center;
        background-repeat  : no-repeat;
        min-width          : 85px;

        &:focus-visible {
            outline-color: #3D9FE0;
        }
    }

}


.usuariosInternos,
.drawerManagement {

    .form {
        border-radius: 10px;
        background   : #FFF;
        box-shadow   : 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
        margin       : 40px 15px 50px 15px;
        padding      : 45px 50px 80px 50px;

        .actionButton {
            margin-right: 0;
            margin-top  : 10px;

        }
    }

    input {
        margin-bottom: 20px;
    }
}

.cession .formRegister.form,
.check .formRegister.form,
.formRegister.form.operationForm {
    margin-top: 40px;
}

.operationForm {
    button.btnDelete {
        background-color: transparent !important;
        margin-top      : -12px;

        svg path {
            fill: #9e4772 !important;
        }
    }

}

.modalLibrador {
    height          : 100%;
    position        : absolute;
    right           : 0;
    top             : 0;
    width           : 100%;
    z-index         : 997;
    background-color: rgba(0, 0, 0, 0.8509803922);
    padding         : 2%;
    text-align      : center;

    &__wrapper {
        border-radius: 10px;
        background   : #FFF;
        box-shadow   : 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
        margin       : 40px 15px 50px 15px;
        padding      : 45px 50px 80px 50px;
        max-width    : 1217px;
        margin       : auto;
        margin-top   : 5%;
    }

    input:focus-visible {
        outline-color: #3D9FE0;
    }

    h1 {
        text-align: left;
    }

    label {
        text-align: -webkit-auto;
    }

    .formRegister.form {
        display              : grid;
        grid-template-columns: 1fr 1fr;
        gap                  : 20px 50px;
    }

    button.actionButton {
        max-width  : 136px;
        text-align : center;
        max-height : 48px;
        float      : right;
        margin-top : 30px;
        margin-left: 75%;
    }
}

// NEW OPREATION FORM

.newOperation {

    .radioWrapper {
        display              : grid;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom        : 35px;
        gap                  : 0px;

        label {
            display        : flex;
            align-items    : center;
            justify-content: flex-start;
            max-width      : 135px;

        }

        input {
            margin-bottom: 0;
            min-height   : 28px;
            cursor       : pointer;
        }

    }

    h1 {
        margin-bottom: 0px !important;
    }

    .btnOperations {
        background-color: #3D9FE0;
        color           : #fff;
        font-size       : 14px;
        padding         : 10px 20px;
        border-radius   : 5px;
        font-weight     : 600;
        cursor          : pointer;
        transition      : all 0.3s;
        box-shadow      : 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
        line-height     : 46px;
    }

    .actBtn {
        margin-top: 60px;
    }

    h4 {
        color        : #000000;
        font-weight  : 700;
        font-size    : 26px;
        margin-bottom: 10px;
    }

    .editar {
        background-color: #3D9FE0;
        color           : #fff;
        padding         : 13px 18px;
        min-height      : 40px;
        border-radius   : 5px;
        font-weight     : 600;
        cursor          : pointer;
        transition      : all 0.3s;
        font-size       : 14px;


        &:hover {
            background-color: #0073c0;
        }
    }

    .eliminar {
        background-color: #9e4772;
        color           : #fff;
        padding         : 13px 18px;
        min-height      : 40px;
        border-radius   : 5px;
        font-weight     : 600;
        cursor          : pointer;
        transition      : all 0.3s;
        font-size       : 14px;


        &:hover {
            background-color: #753454;
        }
    }

    .topArea {
        display        : flex;
        justify-content: space-between;
        margin-bottom  : 20px;
        margin-top     : 30px;

    }

    .dropArea {
        display              : grid;
        grid-template-columns: 1fr 1fr;
        gap                  : 5px 50px;
    }

    hr {
        width           : 100%;
        height          : 1px;
        /* color        : #000; */
        background-color: #d6d6d6;
        margin-top      : 35px;
        margin-bottom   : 50px;
    }

    h2 {
        margin-top : 15px;
        font-weight: 600;
        color      : #000000;
    }

    .checksImage__wrapper {
        padding-top   : 20px !important;
        padding-bottom: 0 !important;
    }
}

.btnOperations {
    background-color: #3D9FE0;
    color           : #fff;
    font-size       : 14px;
    padding         : 10px 20px;
    border-radius   : 5px;
    font-weight     : 600;
    cursor          : pointer;
    transition      : all 0.3s;
    box-shadow      : 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
    // line-height     : 46px;
}