//Container
$container-size: 1390px;

//Colors
$body-background         :#f5f7f8;
$primary-dark            :#20273b;
$primary-light           :#3D9FE0;
$secondary-dark          :#07BB7A;
$secondary-light         :#2FFFB4;
$text-color              :#0f0f0f;
$sidebar-background      :#3d4953;
$sidebar-links           :#ffffff;
$h1-titles               :#3d4953;



//Breakpoint
$breakpoint-tablet: 640px;
$breakpoint-laptop: 1024px;
$breakpoint-desktop: 1280px;