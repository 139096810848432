@use '../../../styles/global.scss';

.topContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 40px 15px 0px 15px;

    button.btnDark {
        background-color: global.$primary-light;
        color: #fff;
        padding: 13px 18px;
        min-height: 40px;
        border-radius: 5px;
        font-weight: 600;
        cursor: pointer;
        transition: all .3s;
        font-size: 14px;

        &:hover {
            background-color: #378dc8;
        }
    }

    button.modificar.btnDark {
        background-color: #29615B;

        &:hover {
            background-color: #224743;
        }
    }

    button.eliminar.btnDark {
        background-color: #9e4772;

        &:hover {
            background-color: #753454;
        }
    }

    input.inputSearch {
        padding: 0 45px 0 15px;
        border-radius: 5px;
        background: #fff url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/XMLID_223.svg?v=1709905200) no-repeat;
        background-position-x: 92%;
        background-position-y: 47%;
        font-size: 14px;
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.03);

        &:focus-visible {
            outline-color: #3D9FE0;
        }
    }

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-around;
        justify-items: stretch;

        .rightArea,
        .leftArea {
            display: flex;
            gap: 15px;
            margin-top: 50px;
            
        }

        .rightArea {
            justify-content: flex-end;
        }

        .elementView{
            display: flex;
            gap: 15px;
            justify-content: flex-end;
        }
    }
}

.rowView{
    flex-direction: row;
    justify-content: space-between;

    .topContent__wrapper {
        grid-template-columns: unset;
    }
}