.previewImage {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    width: 200px;
    height: 200px;
    object-fit: cover;
   box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.previewImage:hover {opacity: 0.7;}

.imageViewer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.9);
    display: flex;
    flex-direction: column;
}

.noImage{
    width: 200px;
    height: 200px;
   box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
   color: black;
   text-align: center;
   padding: 20px;
   overflow-wrap: break-word;
}

.openFileContainer{
    width: 263px;
    position: fixed;
    bottom: 30px;
    right: calc(50% - 131px);
}

.modalContent {
    margin: auto;
    width: 80%;
    max-width: 700px;
    object-fit: scale-down;
    max-height: 80vh;
}

.close{
    color: white;
}

.images{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;

}

.Example__container__document{
    width: 200px;
   color: #3D9FE0;
}

.Example__container__document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Example__container__document .react-pdf__Page {
    margin: 1em 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  }
  
  .Example__container__document .react-pdf__message {
    padding: 20px;
  }

  .fileLabel {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #000;
}


.backoffice-info {
    margin-bottom: 20px; /* Ajusta el margen según sea necesario */
  }
  
  .backoffice-title {
    width: 100%;
    font-weight: bold;
    margin-bottom: 10px; /* Espacio después del título */
  }
  
  .backoffice-detail > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px; /* Espacio entre las líneas de detalle */
  }
  
  .backoffice-detail label {
    font-weight: bold;
  }
  
  .backoffice-detail span {
    margin-left: 10px; /* Espacio entre el label y su valor */
  }
  