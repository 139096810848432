@use '../../../styles/global.scss';

.sidebar {
  min-width: 275px;
  min-height: 100vh;
  background-color: global.$sidebar-background;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  position: sticky;
  top:0px;
  

  &__link,
  button.ActionButton {
    font-size: 15px;
    font-weight: 500;
    color: global.$sidebar-links;
    transition: all .3s;
    padding: 15px 20px;
    border-radius: 5px;
    margin-bottom: 7px;
    align-items: center;
    gap: 2px;

    &:hover {
      background-color: global.$primary-light;
      color: #eaeaf1;
    }

    &.active {
      background-color: global.$primary-light;
      padding: 15px 20px;
      color: #eaeaf1;
      align-items: center;
    }
    &:focus-visible {
      outline-color: #3D9FE0;
    }

  }

  &__link {
    display: flex;

    svg {
      width: 20%;
    }
  }

  a.home {
    margin-top: 34px;
  }

  button.actionButton {
    width: 100%;
    text-align: left;
    cursor: pointer;
    background-color: transparent;
    color: #000;
    box-shadow: none;
    margin-top: 30px;
    font-weight: 500;
    font-size: 17px;
    display: flex;

    svg {
      width: 20%;
    }

    &:hover {
      background-color: #f9f9f9;
    }
  }

  img.logoSidebar {
    max-width: 228px;
    padding-left: 7px;
    padding-top: 15px;
    padding-bottom: 10px;
  }
  
  a.home.sidebar__link svg {
    margin-top: -2px;
}
  a.client.sidebar__link svg {
    width: 22px;
    height: 22px;
    margin-right: 7px;
    margin-top: -4px;
    margin-left: 2px;
  }

  a.user.sidebar__link svg {
    width: 22px;
    height: 22px;
    margin-right: 9px;
    margin-top: -1px;
    margin-left: 2px;
  }


  


  .btnLogout  {
    margin-top: 35px;
    border-top: 1px solid rgb(234 234 241 / 25%);    
    display: flex;
    align-items: flex-end;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 10px;


    .version {
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      padding-left: 20px;
    }

    button {      
      font-size: 16px;
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      padding-left: 18px;
      font-weight: 600;
      border-radius: 5px;
      min-height: 52px;
      color: global.$primary-light !important;
      transition: all .3s;

      svg {
        width: 22px;
        height: 22px;
        
        path {
          transition: all .3s;
        }
        
      }
        
  
      &:hover {
        background-color: global.$primary-light;
        color: #fff!important; 
        
        svg path {
          fill: #fff;
        }
      }
  
      &.active {
        background-color: #403c82;
        padding: 15px 20px;
        color: #fff;
      }
    }
  }


  .subLinks svg {
    width: 22px;
    height: 22px;
    margin-right: 9px;
    margin-top: -1px;
    margin-left: 2px;
}

}