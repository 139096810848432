@use '../../../styles/global.scss';

.wrapper:has(.filterModal) {
    background-color: rgb(0 0 0 / 26%);
}

.filterModal~.wrapperTable,
.filterModal~.topContent {
    z-index: -5;
}


.filterModal {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 25%;
    z-index: 997;
    background-color: #758493;
    padding: 2%;

    h2 {
        color: #fff;
        font-weight: 500;
        margin-bottom: 40px;
        margin-top: 10px;
    }


    label {
        color: #fff;
        font-size: 16px;
        text-transform: initial !important;
        margin-bottom: 23px;
        font-weight: 500;
    }

    select {
        -webkit-appearance: none;
        appearance: none;
        background-color: #97a3af;
        background-image: url(https://cdn.shopify.com/s/files/1/0622/8577/5024/files/Vector_9_1.svg?v=1709922982);
        background-position: right 10px center;
        background-repeat: no-repeat;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font-family: Source Sans Pro, sans-serif;
        font-size: 16px;
        outline-color: #3D9FE0;
        padding: 10px 12px;
        width: 100%;
        margin-top: 10px;
    }

    input[type="number"] {
        background-color: #97a3af;;
        background-position: right 10px center;
        background-repeat: no-repeat;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font-family: Source Sans Pro, sans-serif;
        font-size: 16px;
        outline-color: #3D9FE0;
        padding: 10px 12px;
        width: 100%;
        margin-top: 10px;
    }

    input[type="checkbox"] {
        float: left;
        margin: 3px 9px 0px 0px;
    }


    .modalBtns {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
        margin-top: 50px;
        flex-wrap: wrap;
    }

    .modalBtns .actionButton:first-child {
        min-width: 30%;
        border: 1px solid transparent;

        &:hover {
            background-color: #3d4953;
            color: #eaeaf1;
            border: 1px solid #3d4953;
        }

    }

    .modalBtns .actionButton:first-child {
        border: 1px solid #3d4953;
        background-color: #3d4953;
        color: #ffffff;
        min-width: 30%;


        &:hover {
            background-color: #33363a;
            color: #fff;
            border: 1px solid #33363a;
        }
    }

    .modalBtns .actionButton:nth-child(2n) {
        min-width: 30%;
        border: 1px solid #3D9FE0;

        &:hover {
            background-color: #4498cf;
            color: #eaeaf1;
            border: 1px solid #4498cf;
        }

    }
}

.modal-overlay {

    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 997;
    background-color: #000000d9;
    padding: 2%;
    text-align: center;


    .modal {
        background-color: #3d4953;
        padding: 55px;
        border-radius: 11px;
    }

    h2 {
        color: #fff;
    }

    .modal-content {
        color: #fff;
        margin-top: 7px;
    }

    .modal-overlay-wrapper {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding-top: 15%;
    }

    .modal-buttons {
        display: flex;
        justify-content: center;
        padding-top: 25px;
        gap: 25px;
    }
    

    button.actionButton[aria-label="Cancel Button"] {        
        background-color: #8693a0;
        color: #ffffff;

        &:hover {
            background-color: #6d7782;
            color: #fff;
          
        }
    }
}


.drawer-modal-overlay {

    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 997;
    background-color: #000000d9;
    padding: 2%;
    text-align: center;


    .drawer-modal {
        background-color: #f4f7f8;
        padding: 55px;
        border-radius: 11px;
        width: 60%;
        position: relative;
    }

    .drawer-modal-overlay-wrapper {
        display: flex;
        justify-content: center;
        align-content: center;
        padding-top: 30px;
    }
}