@use '../../../styles/global.scss';

.userTable {

    &__itemTitle,
    &__itemContent {
        background-color: #fff;
        box-shadow      : -1px 3px 2px 0px rgb(0 0 0 / 4%);
        border-radius   : 5px;
        display         : flex;
        gap             : 50px;
        margin-bottom   : 10px;
        align-items     : center;
        justify-content : space-between;
    }

    &__itemTitle {
        font-size  : 15px;
        font-weight: 700;
        color      : #000;
        padding    : 15px 20px;
    }

    &__itemContent {
        padding    : 8px 20px;
        font-size  : 14px;
        font-weight: 500;
        color      : #1b1b1b;
        min-height : 60px;
        cursor     : pointer;
    }

    &__itemContent {
        color: #3d4953;
    }

}

button.btnDelete svg {
    margin-top: 12px;
}

.yellowStatus {
    background-color: #EEE8A9;
    padding         : 11px 4px;
    border-radius   : 3px;
    text-align      : center;
    font-size       : small;
}

.redStatus {
    background-color: #9E4772;
    padding         : 11px 4px;
    border-radius   : 3px;
    color           : #fff;
    text-align      : center;
    font-size       : small;
}

.greenStatus {
    background-color: #29615B;
    padding         : 11px 4px;
    border-radius   : 3px;
    color           : #fff;
    text-align      : center;
    font-size       : small;
}

.column {
    display      : flex;
    width        : 100%;
    word-wrap    : break-word;
    text-overflow: ellipsis;

    &.ID {
        width: 30%;
    }

    &.Monto {
        width: 50%;
    }

    &.Fecha-de-vencimiento {
        width: 50%;
    }
}

// .column-ID, .column-Monto, .column-Estado, .column-Estado {
//     // min-width: 150px;  
//     word-wrap: break-word;

// }

// .drawersTable.wrapperTable > , .column-Nombre, .column-Razón.Social  {
//     width: 20%;
// }

// .drawersTable.wrapperTable > , .column-RUT{
//     width: 25%;
// }

// .drawersTable.wrapperTable > , .column-Nombre.Comercial {
//     width: 35%;
// }

// .userTable.wrapperTable > , .column-Email{
//     width: 30%;
// }

// .clientsTable.wrapperTable > , .column-Transacciones.Completadas, .column-Transacciones.En.Curso, .column-Estado{
//     width: 10%;
// }

// .clientsTable.wrapperTable > , .columb.column-Razón.Social {
//     width: 20%;
// }

// .clientsTable.wrapperTable > , .column-Nombre, .column.column-RUT{
//     width: 25%;
// }

// .operationsTable.wrapperTable > , .column-Cantidad.de.cheques , .column-Cantidad.de.cesiones{
//     width: 21%;
// }

// .operationsTable.wrapperTable > , .column-Cliente , .column-Empresa{
//     width: 28%;
// }

// // .checkTable.wrapperTable > , .column-ID{
// //     width: 7%;
// // }

// // .checkTable.wrapperTable > , .column-Monto{
// //     width: 13%;
// // }

// // .checkTable.wrapperTable > , .column-Estado{
// //     width: 30%;
// // }

// // .checkTable .wrapperTable > ,  .column-Cliente{
// //     width: 50%;
// // }

// .cessionTable.wrapperTable > , .column-Id {
//     width: 7%;
// }

// .cessionTable.wrapperTable > , .column-Factura.Asociada, .column-Tasa.Acordada{
//     width: 20%;
// }

// .cessionTable > .wrapperTable > , .column-Ente{
//     width: 25%;
// }

// .loansTable.wrapperTable > , .column-Plazo {
//     width: 20%;
// }

// .loansTable.wrapperTable > , .column-Monto {
//     width: 13%;
// }



.userTable__itemTitle {
    padding: 11px 20px;
}

.userTable__itemContent {
    min-height: 54px;
}


//CLIENTS TABLE
// .clientsTable {


//     .column-RUT {
//         width: 11%;
//     }

//     .column-Nombre {
//         width: 34%;
//     }
//     .column-Estado {
//         width: 9%;
//     }
//     .column-Razón.Social {
//         width: 30%;
//     }
//     .Completadas, .En.Curso {
//         width: 8%;
//     }
// }



//LIBRADORES
// .drawersTable {

//     .column-RUT {
//         width: 12%;
//     }

//     .column-Nombre {
//         width: 30%;
//     }

//     .column-Razón.Social {
//         width: 28%;
//     }
// }

//CHEQUES

// .checkTable.wrapperTable {

//     .column-ID {
//         width: 6%;
//     }
//     .userTable__itemContent {
//         gap: 58px;
//     }
//     .column-Monto {
//         width: 11%;
//     }
//     .userTable__itemContent {
//         padding: 7px 20px;      
//     }
//     .column-Fecha.de.vencimiento {
//         width: 8%;
//         text-align: center;
//     }


// }

//Cheqes

.clientManagement.check {

    .formRegister__inputArea,
    .formRegister__inputArea {
        display              : grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap                  : 10px 50px;
    }

    label {
        font-size    : 15px;
        margin-bottom: 6px;
    }

    .formRegister input {
        margin-bottom: 5px;
    }

    .dropTop {
        display              : grid;
        grid-template-columns: 1fr 1fr;
        gap                  : 50px;
    }

    .detailWrapper {
        display              : grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap                  : 20px 50px;
    }

    .backoffice-detail label:nth-child(2) {
        font-weight: 400;
    }

    .internalMessage {
        margin-top: 13px;

        textarea {
            padding      : 20px 20px 0 15px;
            border-radius: 5px;
            background   : #FFF;
            box-shadow   : 0px 4px 30px 0px rgba(0, 0, 0, 0.03);
            font-size    : 16px;
            width        : 100%;
            border       : 1px solid #e0e7eb;
            line-height  : 1.3;
            height       : 100%;
            min-height   : 170px;
            overflow     : auto;
        }
    }
}

.cheksPage {

    .topContent {
        padding: 0px 15px 0px 15px;
    }

    .topContent__wrapper .rightArea,
    .topContent__wrapper .leftArea {
        margin-top: 25px;
    }
}


//Cesiones
.cessionTable.wrapperTable {

    .column-Vencimiento.Acordado {
        width: 9%;
    }

    .column-Estado {
        width: 9%;
    }

    .column-Ente {
        width: 33%;
    }

    .column-Cliente {
        width: 40%;
    }

    .column-Monto {
        width     : 9%;
        text-align: center;
    }
}

.cession {

    .formRegister__wrapper {
        display              : grid;
        grid-template-columns: 1fr 1fr;
        gap                  : 0 50px;
    }
}


//Prestamos

.loan {

    .formRegister.form {
        margin-top: 30px;
    }

    .formRegister__wrapper {
        gap                  : 10px 50px;
        display              : grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}